import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Typography } from "@mui/material";
import CommonCard from "../card";
import Grid from "@mui/material/Grid2";
import config from "../../config";

function ProjectUpcomingResidential() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  const projects = [
    {
        title: "Queens Land",
        location: "Ranikhet",
        imgSrc: `https://d6pltnqzug32h.cloudfront.net/queens.png`,
        link: "https://indusvalleyranikhet.com/",
      },
      {
        title: "Chamba Heights",
        location: "Chamba, Uttarakhand",
        imgSrc: `https://hill-max.com/wp-content/uploads/2021/02/1483275_10152009408408672_1711763387_n-592x444.jpg`,
        link: "",
      },
      {
        title: "Sindhudurg",
        location: "Maharashtra",
        imgSrc: `https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcSWc9sLuGM6ZfhD5qzPLKFH4D9ybNPCXhIg_HS3NrahwMfcmCsWeXR-lsibW06d24kuDPwqNHDTyFjJvaavTivVupISLYiJ0_w641d6Qg`,
        link: "",
      },
  ];

  return (
    <div
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
      className="mt-12"
    >
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <Typography
            // variant="h3"
            align="center"
            gutterBottom
            style={{
              letterSpacing: "0.05em",
              textShadow: "5px 5px 8px #767e8c",
              fontSize: "32px",
            }}
          >
            Upcoming Residential Projects
          </Typography>
        </section>

        <Grid container spacing={4} justifyContent={"center"}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default ProjectUpcomingResidential;
