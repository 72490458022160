import React from "react";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { commonStyle } from "../../constants/constant";
import { Helmet } from "react-helmet";

function About() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <div
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        className="flex flex-col gap-5 bg-cover bg-center"
        style={commonStyle}
      >
        <Helmet>
          <title>About Us | Shubham Buildup Pvt. Ltd.</title>
          <meta
            name="description"
            content="The remarkable journey of Shubham Buildup Pvt. Ltd. began in 2009 intending to provide a comfort-like home amid nature."
          />
        </Helmet>
        <h3
          className="w-fit mx-auto text-3xl md:text-5xl py-5 text-center p-3"
          style={{
            letterSpacing: "0.05em",
            textShadow: "5px 5px 8px #767e8c",
          }}
        >
          About Us
        </h3>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex flex-col gap-3">
            <p
              className="text-lg md:text-xl md:mx-10 px-1 text-justify"
              style={{ lineHeight: "1.7", letterSpacing: "0.03em" }} // Added line height and letter spacing here
            >
              The remarkable journey of Shubham Buildup Pvt. Ltd. began in 2009
              intending to provide a comfort-like home amid nature. Our highly
              dedicated team members are always determined to serve in the best
              possible way to provide tranquility to nature admirers. The
              Shubham Group has successfully launched and delivered its three
              residential projects named Indus Valley Mukteshwar Nainital,
              Ambika Divinity Haridwar, and Indus Valley Shimla, with a growth
              rate of 200% in a decade. Our commitment is to bestow a peaceful
              lifestyle, an escape from the bustling city life, as a gift for
              those cherishing the second phase of life amidst nature. In a
              short span of time, the company has entered other fields such as
              Collaboration, Construction, and Resorts. To manage this
              expansion, The Shubham Group, Delhi transformed into THE SHUBHAM
              GROUP (TSG). After remarkable success in shaping dream houses on
              breathtaking mountains, The Shubham Group now ventures into other
              fields that contain the following companies under itself:
            </p>

            <div
              className="flex flex-col justify-center mx-auto py-10 text-center gap-5 md:gap-10"
              data-aos-anchor-placement="top-center"
            >
              <ol
                className="flex flex-col items-start md:flex-row gap-5 md:gap-20"
                type="1"
              >
                <div>
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Shubham Build Up LTD
                  </li>
                  <br />
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Chail Heights Villa LLP
                  </li>
                </div>
                <div>
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Shubham Buildtech LLP
                  </li>
                  <br />
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Chail Heights Resort LLP
                  </li>
                </div>

                <div>
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Shubham Buildstone LLP
                  </li>
                  <br />
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Nirmanam Realtors LLP
                  </li>
                </div>
                <div>
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Shape My House
                  </li>
                  <br />
                  <li className="text-lg text-start md:text-xl hover:underline">
                    Neverland Resorts
                  </li>
                </div>
              </ol>
            </div>

            <div className="flex flex-col justify-center mx-auto text-center gap-5 md:gap-10 pb-10">
              <h5
                className="w-fit mx-auto text-3xl md:text-5xl text-center p-3"
                style={{
                  letterSpacing: "0.05em",
                  // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                  textShadow: "5px 5px 8px #767e8c",
                }} // Added letter spacing here
              >
                Startup Journey
              </h5>
              <p
                className="text-lg md:text-xl md:mx-10 px-1 text-justify text-gray-700"
                data-aos-anchor-placement="top-center"
                style={{ lineHeight: "1.7", letterSpacing: "0.03em" }} // Added line height and letter spacing here
              >
                The Shubham Group was started by Mr Agam Aggarwal in 2009 with a
                vision of creating value in real estate. At the time when the
                company started, there was some void in the market of the
                concept of second homes on hill stations for people living in
                big metropolis like Delhi NCR. Some of these individuals had a
                strong desire of often travelling to mountains and wished to
                have a house in the lap of Himalayas. This would make it
                possible for them to spend their leisure time in complete peace
                and beauty of nature. This is why the company launched its first
                residential project near Nainital and till now has delivered 2
                such projects in Uttarakhand and 1 in Himachal Pradesh with 100%
                happy and satisfied customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
