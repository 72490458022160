import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Typography } from "@mui/material";
import CommonCard from "../card";
import Grid from "@mui/material/Grid2";
import config from "../../config";

const resorts = [
  {
    id: 1,
    title: "Neverland Resort",
    location: "Mukteshwar Nainital",
    imgSrc: `https://d6pltnqzug32h.cloudfront.net/never.png`,
    link: "http://www.neverlandresorts.com",
  },
  {
    id: 2,
    title: "Chail Heights Resorts",
    location: "Chail Himachal Pradesh",
    imgSrc: `${config.S3_URL}/ChailResortLogo.png`,
    link: "http://www.chailheightsresort.com",
  },
];

function Project2_Resorts() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <Container>
        <section className="flex flex-col justify-center gap-5 lg:gap-16 p-3">
          <Typography
            align="center"
            gutterBottom
            style={{
              letterSpacing: "0.05em",
              textShadow: "5px 5px 8px #767e8c",
              fontSize: "32px",
            }}
          >
            Upcoming Series Of Resorts
          </Typography>
        </section>

        {/* Resort Cards */}
        <Grid container spacing={4} justifyContent={"center"}>
          {resorts.map((resort) => (
            <Grid item xs={12} sm={6} md={4} key={resort.id}>
              <CommonCard
                title={resort.title}
                location={resort.location}
                imgSrc={resort.imgSrc}
                link={resort.link}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Project2_Resorts;
