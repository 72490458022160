import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

const CommonCard = ({ title, location, imgSrc, link, onClick }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        mx: "auto",
        borderRadius: "12px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover": {
          transform: "scale(0.95)",
          transition: "transform 0.5s ease",
        },
      }}
      onClick={onClick}
    >
      <CardActionArea
        component="a"
        href={link}
        target="_blank"
        rel="noreferrer"
        sx={{ flexGrow: 1, }}
      >
        <CardMedia
          component="img"
          image={imgSrc}
          alt={title}
          sx={{objectFit: "cover",height:"250px"}}
        />
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontFamily: "merriweather",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontFamily: "merriweather",
            }}
          >
            {location}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CommonCard;
