import React from "react";
import config from "../../config";
import BlogCard from "./BlogCard";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const blogPost = [{
    image: `${config.S3_URL}/blog-the-shubham.webp`,
    title: "The Shubham Group's Journey",
    date: "July 25, 2024",
    href: "/blog/the-shubham-group-journey",
  },
  {
    image: `${config.S3_URL}/blog-second-home.webp`,
    title: "Why second homes are the Future of Smart Real Estate Investment",
    date: "August 03, 2024",
    href: "/blog/second-homes-concept",
  },{
    image: `${config.S3_URL}/blog3.png`,
    title:
      "Collaboration and construction expansion the Shubham Group diversification",
    date: "October 3, 2024",
    href: "/blog/collaboration-construction-expansion",
  },{
    image: `https://theshubhamgroupreact.s3.eu-north-1.amazonaws.com/blog+shubham+group.jpg`,
    title:
      "Need of the hour : Investment in Real Estate",
    date: "November 12, 2024",
    href: "/blog/need-of-the-hour",
  }]

  return (
    <div className="flex flex-col gap-3 md:gap-10 px-5 md:px-20 mb-20">
      <h1 className="w-fit mx-auto text-3xl md:text-5xl py-5 pt-16  text-center p-3">
        OUR BLOGS
      </h1>
      <Helmet>
        <title>Our Blogs | Shubham Buildup Pvt. Ltd.</title>
        <meta
          name="description"
          content="Read our blogs to know more about the real estate industry, the future of smart real estate investment, and the journey of The Shubham Group."
        />
      </Helmet>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
        

      {
      blogPost.map((e,i)=>(
        <BlogCard image={e.image} title={e.title} date={e.date} href={e.href} key={i} />
      ))
    }



      </div>
    </div>
  );
};

export default Blogs;
