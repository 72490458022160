import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/screens/Home";
import About from "./components/screens/About";
import Contact from "./components/screens/Contact";
import Associates from "./components/screens/Associates";
import Floating from "./components/Floating";
// import Gallery from './components/screens/Gallery';
import Gallery2 from "./components/screens/Gallery2";
import Investors from "./components/screens/Investors";
import Our_Projects from "./components/screens/Our_Projects";
// import Vendors from './components/screens/Vendors';
import Footer from "./components/Footer";
import Error from "./components/screens/Error";
import Project1_Residential from "./components/screens/Project1_Residential";
import Project2_Resorts from "./components/screens/Project2_Resorts";
import Blogs from "./components/blogs/Blog";
import Blog1 from "./components/blogs/the-shubham-blog";
import Blog2 from "./components/blogs/second-home-blog";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Blog3 from "./components/blogs/collaboration-construction-expansion";
import Blog4 from "./components/blogs/need-of-the-hour";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/residential" element={<Project1_Residential />} />
        <Route exact path="/resorts" element={<Project2_Resorts />} />
        <Route exact path="/our projects" element={<Our_Projects />} />
        <Route exact path="/gallery" element={<Gallery2 />} />
        {/* <Route exact path='/vendors' element={<Vendors/>} />  */}
        <Route exact path="/investors" element={<Investors />} />
        <Route exact path="/associates" element={<Associates />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/blogs" element={<Blogs />} />

        <Route
          exact
          path="/blog/the-shubham-group-journey"
          element={<Blog1 />}
        />
        <Route exact path="/blog/second-homes-concept" element={<Blog2 />} />
        <Route
          exact
          path="/blog/collaboration-construction-expansion"
          element={<Blog3 />}
        />
        <Route
          exact
          path="/blog/need-of-the-hour"
          element={<Blog4 />}
        />

        <Route exact path="*" element={<Error />} />
      </Routes>
      <Floating />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
