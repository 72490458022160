import React, { useEffect, useRef } from "react";
import Banner from "../Banner";
// import Contact from "./Contact";
// import Gallery from "./Gallery";
import About from "./About";
import MainHeading from "../MainHeading";
import CompanyParts from "./CompanyParts";
// import Gallery2 from "./Gallery2";
// import bg from "../../assets/photos/bg_image3.png"
// import Marquee from "./Marquee";
// import img from "../../assets/photos/Logos.banner..3.jpg"
import img from "../../assets/photos/logoBanner.png";

import LocomotiveScroll from "locomotive-scroll";
import OurProjects from "./Our_Projects";
import AOS from "aos";
import "aos/dist/aos.css";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

function Home() {
  const containerRef = useRef(null);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div
      className="bg-white"
      ref={containerRef}
      data-aos-anchor-placement="top-center"
      data-aos="fade-up"
    >
      <Banner />
      <Box className="bg-contain bg-center flex flex-col md:gap-10 md:p-5 pt-0 w-full">
        <MainHeading />
        <About />
        <OurProjects />
        <CompanyParts />
        <img
          data-scroll
          data-scroll-section
          data-scroll-speed=".1"
          className="w-full "
          src={img}
          alt="our brands"
        />
        <Helmet>
          <title>The Shubham Group</title>
          <meta
            name="description"
            content="The Shubham Group is a real estate company that provides residential, resorts, and trading projects."
          />
        </Helmet>
      </Box>
    </div>
  );
}

export default Home;
